<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" @click="handleAdd">批量生成</el-button>
                <el-button @click="handleClick({type: 'all'})" type="primary">全量下载</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键">
            </el-table-column>
            <el-table-column
                prop="type"
                label="话题类型">
                <template slot-scope="scope">
                  <span>{{ scope.row.type }}</span>
                  <br>
                  <el-button @click="handleClick(scope.row)" type="text" :disabled="scope.row.status !== 2">下载</el-button>
                  <el-button @click="handleDelete(scope.row)" type="text" :disabled="scope.row.status !== 3">销毁</el-button>
                </template>
            </el-table-column>
            <el-table-column
                prop="title"
                label="标题">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                width="55">
            </el-table-column>
            <el-table-column
                prop="createUserId"
                label="创建人">
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog title="批量生成文章" :visible.sync='open' width='500px' :before-close='cancel'>
      <el-card>
        <div slot="header" class="clearfix">
          <span>录入话题</span>
        </div>
        <el-select
          v-model="typeList"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="请选择话题">
          <el-option
            v-for="(item, index) in typeList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-card>  
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { aiTitlePage, aliContentAsync, contentDirectoryDownload, contentDirectoryDelete } from '@/service/headlines';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        code: [
          { required: true, message: '角色代码不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
      },
      rightForm:{
        roleId: null,
        userIds: null
      },
      rightOpen: false,
      userIds: [],
      typeList: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      aiTitlePage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleClick(row) {
      contentDirectoryDownload({downloadPath: row.rootPath, id: row.id}).then(response => {
        let url = window.URL.createObjectURL(new Blob([response.data], {type: `application/zip;`}));
        const a = document.createElement("a");
        a.href = url;
        a.download = row.type;
        document.body.appendChild(a);
        a.style.display = "none";
        a.click();
        document.body.removeChild(a);
        this.getList();
      });
    },
    handleAdd() {
      this.open = true;
    },
    tableEdit(){
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.typeList = [];
    },
    /** 提交按钮 */
    submitForm: function() {
      aliContentAsync({titleList:this.typeList}).then(response => {
        if (response.code === 0) {
          this.cancel();
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });
        }
      });
    },
    handleDelete(row){
      contentDirectoryDelete({downloadPath: row.rootPath, id: row.id}).then(response => {
        if (response.code === 0) {
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });
        }
      });
    },
    tableDel(){
    }
  }
};
</script>